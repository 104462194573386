import React from 'react';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import group1 from '/images/img_12.jpg';

const logoWidth = '70%';
function OurNewsSection() {
  return (
    <div
      className="site-section"
      id="news"
    >
      <Container fluid="md">
        <Row>
          <Col
            xs={12}
            sm={6}
          >
            <Row>
              <Col className="centered"><h1 className="news-font">Our Partners</h1></Col>
            </Row>
            <Row className="centered" id="grayscale">
              <Col className="centered" xs={6} md={6}>
                <img
                  src="/images/partner_2.png"
                  width={logoWidth}
                  alt="ut austin logo"
                />

              </Col>
              <Col className="centered" xs={6} md={6}>
                <br />
                <img
                  src="/images/techstars.jpg"
                  width={logoWidth}
                  alt="teachstars logo"
                />

              </Col>

            </Row>
            <Row>

              <Col className="centered" xs={6} md={6}>
                <br />
                <img
                  src="/images/blackstone.png"
                  width={logoWidth}
                  alt="blackstone logo"
                />

              </Col>

              <Col className="centered" xs={6} md={6}>
                <br />

                <img
                  src="/images/partner_4.jpg"
                  width={logoWidth}
                  alt="heat and soul care logo"
                />

              </Col>

            </Row>
          </Col>
          <Col xs={12} sm={6} className="mobile-border-top">
            <Row>
              <Col className="centered"><h1 className="news-font">Featured In</h1></Col>
            </Row>
            <br />
            <Row className="centered" id="grayscale">
              <Col md={6} xs={6}>
                <img
                  src="/images/today-show.png"
                  width={logoWidth}
                  alt="Houston Chronicle Logo"
                />

              </Col>
              <Col md={6} xs={6}>
                <img
                  src="/images/MSN-Logo.png"
                  width={logoWidth}
                  alt="MSN Logo"
                />

              </Col>

              <Col md={6} xs={6}>
                <br />
                <img
                  src="/images/fox-news-logo.jpg"
                  width={logoWidth}
                  alt="Fox News Logo"
                />

              </Col>
              <Col md={6} xs={6}>
                <br />

                <img
                  src="/images/houston-chronicle-logo.jpg"
                  width={logoWidth}
                  alt="Mix 94.7 Logo"
                />

              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row  className = "centered">

                    <Col>
                    <br/><br/>
                        <a className = "underline" href = "/whatsnew">See More</a>

                    </Col>

                </Row> */}
      </Container>

    </div>
  );
}

export default OurNewsSection;
