import React from 'react';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ImageByText from '@/components/homepage/ImageByText';

function StepTwoSection() {
  return (
    <div className="site-section" id="border-bottom">
      <Container>
        <ImageByText
            imgURL = "/images/img_12.jpg"
            stepNum = {2}
            title = {"Get Matched & Meet Your Big or Mini"}
            content = {<>
                          You’ll be automatically matched with a Big or Mini who has similar interests. All you have to do is sit back and wait. We’ll set up a chat for you to phone or video call your Big or Mini (finally!)

</>}
            imageFirst = {false}
          />
        </Container>

    </div>
  );
}

export default StepTwoSection;
