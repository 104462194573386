import GhostContentAPI from '@tryghost/content-api';

// Create API instance with site credentials
const api = new GhostContentAPI({
  url: 'https://blog.bigandmini.org',
  key: '85da172d1531ded0a7f170c700',
  version: 'v3',
});

export async function getPosts() {
  return await api.posts
    .browse({
      limit: 'all',
      include: 'tags,authors',
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function getNPosts(n) {
  return await api.posts
    .browse({
      limit: n,
      include: 'tags,authors',
    })
    .catch((err) => {
      console.error(err);
    });
}

export async function getSinglePost(postSlug) {
  return await api.posts
    .read({
      slug: postSlug,
      include: 'tags,authors',
    })
    .catch((err) => {
      console.error(err);
    });
}
