import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TestimonialCard from '@/components/homepage/TestimonialCard';
import Container from 'react-bootstrap/Container';
import PlayButton from './PlayButton';
import TestimonialSection from './TestimonialSection';

function VideoSection() {
  return (
    <div className="site-section">
      <Container>
        {/* <Row>
                <Col md ={6}>
                <div className = "video-section">
                <PlayButton/>

                </div>

                </Col>
                <Col md ={6}>
                   <div style = {{width: "80%"}}>
                   <p>
                        "I love how kind and genuine she is and her love for music and life and the stories she tells. She is so interested in hearing about my life and I love that. I also love that she is a Houston Rockets fan like myself. She always seems so happy to talk to me.
                 My connection with [my Big] is something I want to keep for a long time."

                    </p>
                    <h5>Nicole C., Mini from Robbinsville, New Jersey</h5>
                   </div>

                </Col>
            </Row> */}
        <Row className="centered">
          <Col>
            <h1 style={{ marginBottom: '50px' }}>
              What our Bigs and Minis are saying
            </h1>

          </Col>
        </Row>
        <Row>

          <Col lg={{ order: 1 }}>
            {/* <img src='/images/lisa-alanis.jpg'
                            width="100%"
                            alt = "Child and grandfather looking into laptop screen"
                        /> */}
            {/* <PlayButton/> */}
            <iframe
              style={{ width: '100%', height: '100%' }}
              allowFullScreen="true"
              src="https://www.youtube.com/embed/7EZJ__aZH0Q?end=327;modestbranding=1;rel=0"
            />
            {/* <iframe width="100%" height="315" src="https://www.today.com/today/embedded-video/mmvo88225861525"
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen
                        ></iframe> */}
          </Col>
          <Col
            lg={{ order: 1 }}
            className="margin-right"
          >

            <TestimonialSection />
            {/* <p className="block-41-text">
                        "[My Big] is a lovely and funny person. She is so joyful and has many interesting stories to share! I love that she is so adventurous.
               I am so happy you are running this amazing program which helps connect many people from around the world and makes them feel happier. :) Thank you!"

                        </p>
                        <h2>Alanis Y.</h2>
                        <h2 className="sub-heading"> Mini from Boulder, Colorado</h2> */}

          </Col>

        </Row>
      </Container>
    </div>

  );
}

export default VideoSection;
