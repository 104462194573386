import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const propTypes = {
  quote: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
};

function TestimonialCard({ quote, name, designation }) {
  return (
    <>
      <Row
        className="testimonial-card"
      >
        {/* <Col lg = {5} className = "centered">
             <img src={image}
                                   className = "testimonial-image"
                                   alt = "testimonial image"
                               />
             </Col>
             <Col></Col> */}
        <Col>
          <div className="testimonial-intro">
            <h5 className="block-41-text">
              "
              {quote}
              "
            </h5>
            <div><b>{name}</b><p >{designation}</p></div>
            <p></p>
          </div>

        </Col>

      </Row>
      <style jsx global>
        {`
               .testimonial-intro h5{
                font-family: var(--font-overpass);
                color: #555555;
               }
               .testimonial-image {
                width: 65%;
            }
            //    .testimonial-intro{
            //        height: 50vh;
            //      font-size: 25px !important;
                
            //    }
               
               
            //    @media (min-width: 500px) {
            //     .testimonial-intro{
            //         height: 24vh;
  
            //     }
            //    }
            //    @media (min-width: 700px) {
            //     .testimonial-intro{
            //          height: 54vh;
  
            //     }
            //    }
               @media (min-width: 992px) {
                   .testimonial-intro{
                    //    height: 34vh;
                   }
                   .testimonial-image {
                       width: 70% !important;
                   }
               }
              
              
              
               `}

      </style>
    </>
  );
}

TestimonialCard.propTypes = propTypes;
export default TestimonialCard;
