import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
// import group1 from '/images/img_12.jpg';
// import houstonchronicle from '/images/houston-chronicle-logo.jpg';
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';
import TestimonialCard from '@/components/homepage/TestimonialCard';

// import whitebox from '/images/white-box.jpg';

const testimonialWidth = '50%';
const testimonialHeight = '300px';

function TestimonialSection() {
  const [count, setCount] = useState(0);
  const numTestimonials = 5;
  function increaseCount() {
    const newCount = (count + 1) % numTestimonials;
    setCount(newCount);
  }
  function decreaseCount() {
    let newCount = count - 1;
    if (newCount < 0) newCount = numTestimonials - 1;
    setCount(newCount);
  }

  return (
    <div>

      <div className="testimonial-section">
        {/* <Row> */}
        {count == 0 ? (
          <TestimonialCard
            name="Nicole C"
            quote={`I love how kind and genuine she is 
               and her love for music and life and the stories she tells. 
               She is so interested in hearing about my life and I love that. 
               I also love that she is a Houston Rockets fan like myself. 
               She always seems so happy to talk to me.
               My connection with my Big is something I want to keep for a long time.`}
            designation="Mini from Robbinsville, New Jersey"
            image="/images/person_1.jpg"
          />
        ) : null}
        {count == 2 ? (
          <TestimonialCard
            name="Clare D"
            quote={`He was so easy to talk to and get along 
               with.  He's an amazing and fun and inspiring person 
               and I loved listening and talking to him.  He is 
               very friendly and kind and it was the best experience connecting with him.
               The contact with the team, the website, and the 
               scheduling are all really great to work with.`}
            designation="Mini from Ann Arbor, Michigan"
            image="/images/person_1.jpg"
          />
        ) : null}
        {/* {count==2?<TestimonialCard
               name = "Alanis Y."
               quote = {`My Big is a lovely and funny person. She is so joyful and has many interesting stories to share! I love that she is so adventurous.
               I am so happy you are running this amazing program which helps connect many people from around the world and makes them feel happier. :) Thank you!`}
               designation = "Mini from Boulder, Colorado"
               image = '/images/person_2.jpg'
             />:null} */}

        {count == 1 ? (
          <TestimonialCard
            name="Elva R"
            quote={`It was the most delightful experience!
             
               My Mini is just a fantastic young man and it turns out we had so much to talk about.
               My undergraduate degree and graduate degree are from a Catholic university, 
               so we had that in common.
               But once we got beyond that, it was just one thing after another.


               
               `}
            designation="Big from Arlington, Texas"
            image="/images/person_1.jpg"
          />
        ) : null}
        {count == 4 ? (
          <TestimonialCard
            name="Kathryn D"
            quote={`Love talking to my Mini. We talked much longer than I anticipated. 
               We have so many commonalities and I love the questions she asks.  
               The time we spend talking really flies by!
               It was a joy to talk to my Mini and to have a young person in my life!
               I’m so looking forward to talking to her next week!
               
               `}
            designation="Big from Austin, Texas"
            image="/images/person_1.jpg"
          />
        ) : null}

        {count == 3 ? (
          <TestimonialCard
            name="Vidhi D"
            quote={`Big & Mini gives me access to talk openly to people who have 
               lived their lives and are twice my age. This provides  
               not only wisdom but a new perspective, which is 
               essential for anybody in understanding  
               their environment, world and life. 

               I thank the Big & Mini creators for providing me these joys.
               `}
            designation="Mini from Mumbai, India"
            image="/images/person_2.jpg"
          />
        ) : null}
        <div style={{ float: 'center', zIndex: '20', position: 'relative', borderTop: "1px solid rgba(0,0,0,.1)", paddingTop: "10px" }}>
          <span style ={{marginRight: "30px", display: 'inline-block'}}>{count+1}/{numTestimonials}</span>

          <span style = {{display: "inline-block", float: "right"}}>
              <button
              className="clear-button"
              style = {{padding: 0}}
            >
              <FaLongArrowAltLeft
                onClick={decreaseCount}
                className="arrow"
              />
            </button>

            <button
              className="clear-button"
            >

              <FaLongArrowAltRight
                onClick={increaseCount}
                className="arrow"
                style={{ marginLeft: '10px' }}
              />
            </button>
          </span>

        </div>
      </div>

      <style jsx global>
        {`
               .arrow{
                cursor: pointer;
                font-size: 30px;
                color: gray;
                transition: .25s;
                border: 2.5px solid gray;
                border-radius: 50%;
                padding: 6px;
                
            }
            .arrow:hover{
                color: rgb(0, 0, 0);
                border-color: rgb(0, 0, 0);
            }
            .arrow:active{
                transform: scale(.9);
            }

            
              
              
              
               `}

      </style>
    </div>
  );
}
export default TestimonialSection;
