import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function IEPopup() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    const isIE = /MSIE|Trident/.test(ua);

    if (isIE) {
      setShow(true);
      console.log('Internet Explorer detected');
    }
  }, []);

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Browser not supported</Modal.Title>
      </Modal.Header>
      <Modal.Body>Our website does not currently support Internet Explorer, so it may not work as expected. We recommend using Google Chrome or Mozilla Firefox for a better browsing experience.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Got it
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
