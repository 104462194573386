import React, {useState} from 'react';
import {Col, Row, Container} from 'react-bootstrap';
import NewsFeatureLogo from '@/components/homepage/newsfeaturelogo';
import {FaLongArrowAltLeft, FaLongArrowAltRight} from 'react-icons/fa';

function Testimonials() {
    const [pageNum, setPageNum] = useState(0);
    
    const testimonials = [
        {
            content: <>
                      I love how kind and genuine she is and her love for music and life and the stories she tells. She is so interested in hearing about my life and I love that. I also love that she is a Houston Rockets fan like myself. 
            </>,
            name: "Avery",
            type: "Mini",
            location: "Austin",
        },
        {
            content: <>
                      and the stories she tells. She is so interested in hearing about my life and I love that. I also love that she is a Houston Rockets fan like myself. 
            </>,
            name: "Avery",
            type: "Mini",
            location: "Austin",
        },
        {
            content: <>
                     terested in hearing about my life and I love that. I also love that she is a Houston Rockets fan like myself. 
            </>,
            name: "Avery",
            type: "Mini",
            location: "Austin",
        }
    ];
    const increasePageNum = () => {
        if (pageNum === testimonials.length-1) {
            setPageNum(0);
        }
        else {
            setPageNum(pageNum + 1);
        }
    }
    const decreasePageNum = () => {
        if (pageNum === 0) {
            setPageNum(testimonials.length-1);
        }
        else {
            setPageNum(pageNum - 1);
        }    }
    const getTestimonials = testimonials.map((item, i) => {
        return <div className = {pageNum === i?"display":"dontdisplay"}>
        <p>
            {item.content}
      </p>
      <h5>-{item.name} a {item.type} from {item.location}</h5>
        </div>;
    });
  return (
    <div
      className="site-section"
      id="testimonials-section"
    >
      <Container fluid="md" className = "block">
      <img src = "/images/homepage/large-quotes.svg" width = "500" />
      {getTestimonials}
      <hr/>
      <div>
          <span>
            1/{pageNum+1}
          </span>
          <span>
            <button
                className="clear-button"
            >
                <FaLongArrowAltLeft
                onClick={decreasePageNum}
                className="arrow"
                />
            </button>

            <button
                className="clear-button"
            >

                <FaLongArrowAltRight
                onClick={increasePageNum}
                className="arrow"
                style={{ marginLeft: '10px' }}
                />
            </button>
          </span>
      </div>
      </Container>

      <style jsx global>
          {`
          #testimonials-section {
             
          }
          #testimonials-section .block{
            background: rgb(191, 87, 0, .08);
            padding: 40px 40px;

          }
          @media (min-width: 620px) {
            #testimonials-section .block{
                padding: 50px 100px;
              }
          }
          #testimonials-section h5 {
            font-size: 17px;
            color: gray;
          }
          #testimonials-section p {
            font-size: 22px;
            height: 300px;
          }
          @media (min-width: 470px) {
            #testimonials-section p {
                height: 200px;
              }
          }
          @media (min-width: 809) {
            #testimonials-section p {
                height: 250px !important;
              }
          }
          @media (min-width: 1000px) {
            #testimonials-section p {
                height: 150px;
              }
          }
          #testimonials-section img {
            width: 50px;
          }  
          #testimonials-section span:nth-child(1) {
            font-size: 20px;
            color: gray;
          } 
          #testimonials-section span:nth-child(2) {
            float: right;
          } 
          #testimonials-section button {
              font-size: 10px !important;
          }
          #testimonials-section .arrow {
            font-size: 35px !important;
            padding: 6px;
        }
        #testimonials-section .display {

        }
        #testimonials-section .dontdisplay {
            display: none;
        }
          `}
      </style>

    </div>
  );
}

export default Testimonials;
