import Container from 'react-bootstrap/Container';
import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CountUp from 'react-countup';
import Modal from 'react-bootstrap/Modal';
import { FaPlay } from 'react-icons/fa';

function PlayButton() {
  const [show, setShow] = useState(false);

  return (

    <div>

      {/* <a id = "video-button"  className = "hero-button" onClick={() => setShow(true)} href = "#">

            <p style = {{display: "inline-block"}}>Watch the Short Film</p><div style = {{display: "inline-block"}}className="arrow-right"></div></a> */}
      <a href="#playvid" onClick={() => setShow(true)} className="play-button-link">

        {/* <span className="watch-video">
                        <p>Meet Big & Mini</p>
                                        </span> */}
        <img
          className="video-image"
          src="images/lisa-alanis.jpg"
        />
        <span className="play-button">
          <div className="arrow-right" />
        </span>
      </a>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <div className="video">
            <iframe

              src="https://www.youtube.com/embed/7EZJ__aZH0Q?autoplay=1;end=327;modestbranding=1;rel=0"
            />
          </div>
        </Modal.Header>
        <Modal.Body />
      </Modal>
      <style jsx global>
        {`.play-button-link {
        display: block;
        transition: .5s;
    }
    .arrow-right {
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;

        margin: 14px 0 0 20px;
        border-left: 15px solid white;
        transition: border-left .1s;
      }
    .play-button-link:hover .play-button{
        transform: scale(1.07);
    }
    
    .play-button-link:hover .arrow-right{
        border-left: 15px solid white;
    }
    .play-button {
        transition: .3s;
        /* border: 1px solid white; */
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        position: relative;
        margin-left: 0px;
        margin-top: 10px;
        margin-right: 15px;
        background: #dfab1d;
    }
    
      .watch-video {
          /* margin-left: 10px; */
          margin-top: 17px;
          vertical-align: top;
          display: inline-block;
          color: rgb(219, 218, 218);
          transition: .25s;
          font-size: 20px;
          font-weight: 300;
      }
      #video-button {

      }
      
      
      
      .modal-dialog {
        margin: 0;

      }
      .modal-content {
          border: none;
          width: 90vw;
          height: 90vh;
          top: 3vh;
          left: 5vw;
      }
      .modal-header .close {
        color: white;
        transition: .5s;
        text-shadow: none;
        margin-right: -50px;
        color: gray;

      }
      .close:hover {
        color: rgb(209, 209, 209);

      }
      .modal-header {
          border-bottom: 0px;
          display: auto;
          padding: 0;
          /* margin: 20px; */
          
      }
      iframe {
          border: none;
          height: 100%;
          width: 100%;

      }
      .video {
        height: 90vh;
          width: 100%;
        /* margin: 20px; */
      }
      button:focus {
          outline: none;
      }



      .play-button-link:hover .watch-video{
        /* border-bottom: 1px solid black; */
        color: white;
    }
      /* .watch-video p:after {
        content: '';
      left: 0;
      display: inline-block;
      height: 1em;
      width: 100%;
      border-top: 1px solid;
      margin-top: -10px;
      opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: scale(0,1);
        transform: scale(0,1);
    }
    
    .watch-video p:hover:after {
      opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    } */
    .watch-video p {
       margin: 0;
    }
    .watch-video:hover p{
        /* font-weight: bold; */
    }
 

 `}
      </style>
    </div>
  );
}

export default PlayButton;
