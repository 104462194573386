import React from 'react';
import {Col, Row, Container} from 'react-bootstrap';
import NewsFeatureLogo from '@/components/homepage/newsfeaturelogo';

function NewsFeatures() {
    const logoURLs = ["today", "fox", "nbc", "scientific", "yahoo", "parade"];
    const getLogos = logoURLs.map((url, i) => {
        return <NewsFeatureLogo
            url = {url}
            key = {i}
            big = {i < 3}
        />;
    });
  return (
    <div
      className="site-section"
      id="news"
    >
      <Container fluid="md" style = {{textAlign: "center"}}>
      <h1 className="news-font">As seen in</h1>

        <Row>
            {getLogos}
        </Row>
      </Container>

    </div>
  );
}

export default NewsFeatures;
