import React from 'react';
import {Col, Row} from 'react-bootstrap';

function ImageByText(props) {
    return(<>
    
    <Row>

          <Col md={props.imageFirst?{ order: 1 }:{ order: 12}} className="margin-right">
            <img
              src={props.imgURL}
              width="100%"
              alt="Child and grandfather looking into laptop screen"
            />

          </Col>
          <Col md={props.imageFirst?{ order: 12 }:{ order: 1}}>
            <br />
            <h1 className="sub-heading">Step 0{props.stepNum}</h1>
            <h2>{props.title}</h2>
            <p className="block-41-text">
              {props.content}

            </p>

          </Col>

        </Row>

    </>);
}
export default ImageByText;