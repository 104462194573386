import React from 'react';
import { Col, Card } from 'react-bootstrap';

function HomepageNewsCard(props) {
  return (
    <Col
      md={12}
      lg={4}
      className="card-column"
      id="align-left"
    >
      <style jsx global>
        {`
            a.custom-card,
            a.custom-card:hover {
                color: inherit;
                
            }
            `}

      </style>
      <a href={props.link} className="custom-card" variant="primary">
        <Card>
          <Card.Img variant="top" src={props.image} alt="postcard-image" />
          <Card.Body>
            <Card.Subtitle>
              {props.tags ? props.tags[0].name : ''}
            </Card.Subtitle>

            <Card.Title>{props.title}</Card.Title>

            <Card.Text>
              {props.description}
            </Card.Text>
            <Card.Text style={{ fontSize: '0.75em' }}>
              {props.authors ? props.authors[0].name : ''}
              {'\xa0\xa0\xa0\xa0\xa0\xa0'}
              {timeConverter(props.date)}
            </Card.Text>
          </Card.Body>
        </Card>
      </a>
    </Col>
  );
}

function timeConverter(UNIX_timestamp) {
  const a = new Date(UNIX_timestamp);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const time = `${date} ${month} ${year}`;
  return time;
}

export default HomepageNewsCard;
