import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaRegLightbulb, FaUserFriends } from 'react-icons/fa';

function BigAndMiniSection() {
  return (
    <div className="BigAndMiniSection">
      <Container fluid="md">
        <Row>
          {/* <h2>We want every Big to find their Mini.</h2> */}
          <h2>
            Leveraging technology to help you share stories, laugh, and learn
          </h2>
          <div />
        </Row>
        <Row>
          <h5>
            Through phone or video call conversations, an inspiring connection can be made in minutes.
          </h5>
        </Row>
        <Row>
          <Col md={6} className="remove-padding">
            <div className="big-and-mini-card-left"><FaRegLightbulb /></div>
            <div className="big-and-mini-card-right">

              <h3>
                A Big's Life
              </h3>
              <p>
                You have lived a life that cannot be taught in a classroom. You have a unique story and there's a young person out there that can benefit from it.
              </p>
              <a href="https://forms.gle/JcpWdx9rGKjHVysh9" className="underline">
                Sign Up as a Big
              </a>
            </div>

          </Col>
          <Col md={6}>
            <div className="big-and-mini-card-left"><FaUserFriends /></div>
            <div className="big-and-mini-card-right">

              <h3>
                Mini, But Not Minor
              </h3>
              <p>
                You are young and you want to do this thing called life with your eyes wide open: To possibilities. To perspectives. To potential. You want to connect with someone who has lived life firsthand.
              </p>
              <a href="https://forms.gle/JcpWdx9rGKjHVysh9" className="underline">
                Sign Up as a Mini
              </a>
            </div>

          </Col>

        </Row>
      </Container>
    </div>
  );
}

export default BigAndMiniSection;
