import React from 'react';
import {Col} from 'react-bootstrap';

function NewsFeatureLogo(props) {
    return(<>
    
    <Col lg = {2} md = {4} sm = {6} xs = {6}>
        <img
            src={"/images/newsfeatures/" + props.url + ".svg"}
            // width={props.key<3?"20%":"100%"}
            className = {props.big?"big":"small"}
            alt="Houston Chronicle Logo"
        />
    </Col>
    </>);
}
export default NewsFeatureLogo;