import React from 'react';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ImageByText from '@/components/homepage/ImageByText';


function StepOneSection() {
  return (
    <div className="site-section" id="gray-background">
      <Container>
        <Row className="centered">
          <Col>
            <h1>How It Works</h1>
          </Col>
          <br />
          <br />
          <br />
          <br />

        </Row>
          <ImageByText
            imgURL = "/images/mockup-woman-phone.jpg"
            stepNum = {1}
            title = {"Create an Account & Complete Training"}
            content = {<>First create your account. Then, we'll ask for some more detailed information.
            Finally, read the training document and answer a few questions.
            It’s that simple!</>}
            imageFirst = {true}
          />


      </Container>

    </div>
  );
}

export default StepOneSection;
