import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import {
  FaEnvelope, FaPhoneAlt, FaLocationArrow, FaFacebook, FaLinkedin, FaInstagram, FaLinkedinIn, FaTwitter
} from 'react-icons/fa';
import Link from 'next/link'

function Footer() {
  const getYear = () => new Date().getFullYear();
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={4}>
            <h3 className="footer-text-style">WHO WE ARE</h3>
            <p>
              Big & Mini is just a group of do-gooders trying to make a difference. Let's make the world a little brighter.
              {/* We are just a group of volunteers, trying to make a difference. Let's make the world a little brighter. */}
            </p>
            {/* <p>
                        EIN: 85-0716547
                    </p> */}
            {/* <a href = "/about">Learn More</a>
                    <br/>
                    <br/> */}
          </Col>
          <Col md={4}>
            <div className="footer-column">
              <h3 className="footer-text-style">DISCOVER</h3>
              <ul>
                <li>
                  <a href="famsite">
                    Friends and Mentors (FAM)
                  </a>
                </li>
                {/* <li>
                  <a target="_blank" href="http://archive.sendpulse.com/u/NzM2OTI0NQ==/Adc70721d/">
                    Newsletter Archive
                  </a>
                </li> */}
                {/* <li>
                                <a href="teampage">
                                    Our Team
                                </a>
                            </li> */}
                <li>
                  <a href="orgpage">
                    For Organizations
                  </a>
                </li>
                <li>
                  <Link href="https://intercom.help/bigandmini">
                    Help Center
                  </Link>
                </li>

                <li>
                  <a href="mailto:press@bigandmini.org">Press</a>
                </li>
                <li>
                  <a href="https://careers.bigandmini.org" target="_blank">Careers</a>
                </li>

              </ul>
            </div>
          </Col>
          <Col md={4}>
            <div className="footer-column">
              <h3 className="footer-text-style">GET CONNECTED</h3>
              <ul>
                <li>
                  <a>
                    <span className="icon">
                      <FaLocationArrow />
                      {' '}
                    </span>
                    <span className="icon-text" style={{ color: 'white' }}> Austin, Texas, USA</span>
                  </a>
                </li>
                <li>
                  <a href="tel:817-755-0775">
                    <span className="icon">
                      <FaPhoneAlt />
                      {' '}
                    </span>
                    <span className="icon-text"> 	817-755-0775</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:contact@bigandmini.org">
                    <span className="icon">
                      <FaEnvelope />
                      {' '}
                    </span>
                    <span className="icon-text"> contact@bigandmini.org</span>
                  </a>
                </li>
                {/* <li>
                                <a href = "https://www.facebook.com/groups/514104739495160" target = "_blank">
                                    <span className = "icon"><FaFacebook /> </span>
                                    <span className = "icon-text">Big & Mini Facebook Group</span>
                                </a>
                            </li> */}

              </ul>
            </div>
          </Col>
          {/* <Col md = {3}>
                    <h3 className = "footer-text-style">BIG & MINI</h3>
                    <a classname = "social-media"href = "#" target = "_blank">
                      <FaFacebook/>
                    </a>
                    <a classname = "social-media"href = "#" target = "_blank">
                      <FaLinkedin/>
                    </a>
                    <a classname = "social-media"href = "#" target = "_blank">
                      <FaInstagram/>
                    </a>
                </Col> */}
        </Row>
        <Row>
          <Col style={{ marginTop: '50px' }}>
            <div style={{ display: 'inline-block' }}>
              <p>
                Copyright ©
                {' '}
                {getYear()}
                {' '}
                All rights reserved &#8226;
                {' '}
                <a className="footer-link" href="privacy">Privacy</a>
                {' '}
                &#8226;
                {' '}
                <a className="footer-link" href="terms">Terms and Conditions</a>

              </p>
            </div>
            <div style={{
              display: 'inline-block', float: 'right', fontSize: '18px', paddingRight: '15px',
            }}
            >
              <a href="https://www.facebook.com/groups/514104739495160" target="_blank">
                <FaFacebook />
              </a>
              {' '}
              &nbsp; &nbsp;
              <a href="https://www.instagram.com/bigandmini_/" target="_blank">
                <FaInstagram />
              </a>
              &nbsp; &nbsp;
              <a href="https://www.linkedin.com/company/big-and-mini/" target="_blank">
                <FaLinkedinIn />
              </a>
              &nbsp; &nbsp;
              <a href="https://twitter.com/BigandMini" target="_blank">
                <FaTwitter />
              </a>

            </div>
          </Col>

        </Row>
      </Container>
    </div>
  );
}

export default Footer;
